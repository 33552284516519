<template>
  <div>
    <v-tabs
        v-model="tab_content"
        show-arrows
        style="margin-bottom:1rem;"
    >
      <v-tab
          href="#tab-0"
      >
        <v-icon
            class="me-3"
            size="20"
        >
          {{ subTabs[0].icon }}
        </v-icon>
        <span>{{ subTabs[0].title }}</span>
      </v-tab>
      <v-tab
          href="#tab-1"
      >
        <v-icon
            class="me-3"
            size="20"
        >
          {{ subTabs[1].icon }}
        </v-icon>
        <span>{{ subTabs[1].title }}</span>
      </v-tab>
      <v-tab
          href="#tab-2"
      >
        <v-icon
            class="me-3"
            size="20"
        >
          {{ subTabs[2].icon }}
        </v-icon>
        <span>{{ subTabs[2].title }}</span>
      </v-tab>
      <v-tab
          href="#tab-3"
      >
        <v-icon
            class="me-3"
            size="20"
        >
          {{ subTabs[3].icon }}
        </v-icon>
        <span>{{ subTabs[3].title }}</span>
      </v-tab>
      <v-tab
          href="#tab-4"
      >
        <v-icon
            class="me-3"
            size="20"
        >
          {{ subTabs[4].icon }}
        </v-icon>
        <span>{{ subTabs[4].title }}</span>
      </v-tab>
      <v-tab
          href="#tab-5"
      >
        <v-icon
            class="me-3"
            size="20"
        >
          {{ subTabs[5].icon }}
        </v-icon>
        <span>{{ subTabs[5].title }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab_content">
      <v-tab-item :value="'tab-'+0">
        <v-row class="mt-5 mb-6">
          <v-col>
            <RoomTypeList></RoomTypeList>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item :value="'tab-'+1">
       <div>
         <RoomList></RoomList>
       </div>
      </v-tab-item>
      <v-tab-item :value="'tab-'+2">
        <div>
          xdddddddddd
        </div>
      </v-tab-item>
      <v-tab-item :value="'tab-'+3">
       <div>fffffffffffffff</div>
      </v-tab-item>
      <v-tab-item :value="'tab-'+4">
      <div>eeeeeeeeeeeeeeeee</div>
      </v-tab-item>
      <v-tab-item :value="'tab-'+5">
       <div>ppppppppppppppppp</div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import {
  mdiOfficeBuilding, mdiOfficeBuildingOutline, mdiRoomService, mdiTooltipCheckOutline,
} from '@mdi/js'
import RoomTypeList from "@/views/configuration/modification/cited/RoomTypeList";
import RoomList from "@/views/configuration/modification/cited/RoomList";

export default {
  name: 'ModifCited',
  components: {RoomList, RoomTypeList},
  data() {
    return {
      subTabs: [
        { title: 'Type de chambre', icon: mdiRoomService },
        { title: 'Chambre', icon: mdiRoomService },
        { title: 'Appartement', icon: mdiOfficeBuilding },
        { title: 'Infos cite', icon: mdiOfficeBuildingOutline },
        { title: 'Equipement', icon: mdiTooltipCheckOutline },
        { title: 'Type de sejour', icon: mdiRoomService },
      ],
      tab_content: null,
    }
  },
}
</script>

<style scoped>

</style>
