<template>
  <div>
    <v-tabs
        v-model="tab_content"
        show-arrows
        style="margin-bottom:1rem;"
    >
      <v-tab
          href="#tab-0"
      >
        <v-icon
            class="me-3"
            size="20"
        >
          {{ subTabs[0].icon }}
        </v-icon>
        <span>{{ subTabs[0].title }}</span>
      </v-tab>
      <v-tab
          href="#tab-1"
      >
        <v-icon
            class="me-3"
            size="20"
        >
          {{ subTabs[1].icon }}
        </v-icon>
        <span>{{ subTabs[1].title }}</span>
      </v-tab>
      <v-tab
          href="#tab-2"
      >
        <v-icon
            class="me-3"
            size="20"
        >
          {{ subTabs[2].icon }}
        </v-icon>
        <span>{{ subTabs[2].title }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab_content">
      <v-tab-item :value="'tab-'+0">
        <v-row>
          <v-col
              cols="12"
              md="12"
          >
            <span
                class="text-lg-h5  mb-4"
                style="justify-content: center; display: flex;"
            >
              Enregistrer un Nouveau Employé
            </span>
          </v-col>
        </v-row>
        <v-form
            class="multi-col-validation"
            style="margin-top:1rem; margin-left: 1rem; margin-bottom: 1.5rem;"
        >
          <v-row>
            <v-col cols="12"
                   md="6">
              <v-select
                  v-model="staff.staff_civility"
                  dense
                  name="services_list"
                  :items="['M.','Mme','Mlle']"
                  label="Civilite"
                  outlined
              ></v-select>
            </v-col>
            <v-col
                cols="12"
                md="6"
            >
              <v-text-field
                  v-model="staff.staff_name"
                  dense
                  hide-details
                  label="Nom et Prénom"
                  outlined
                  placeholder="Nom et Prénom"
              ></v-text-field>
            </v-col>

            <v-col
                cols="12"
                md="6"
            >
              <v-text-field
                  v-model="staff.staff_address"
                  dense
                  hide-details
                  label="Addresse"
                  outlined
                  placeholder="Ville Commune"
              ></v-text-field>
            </v-col>

            <v-col
                cols="12"
                md="6"
            >
              <v-text-field
                  v-model="staff.staff_contact"
                  dense
                  hide-details
                  label="Contact"
                  outlined
                  placeholder="Numero de telephone"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                md="6"
            >
              <v-text-field
                  v-model="staff.staff_pseudo"
                  dense
                  hide-details
                  label="Pseudo"
                  outlined
                  placeholder="Pseudonyme"
              ></v-text-field>
            </v-col>
            <v-col cols="12"
                   md="6">
              <v-select
                  v-model="staff.office_id"
                  dense
                  name="functions_list"
                  :item-text="item=>item.office_name"
                  :item-value="'id'"
                  :items="functions_list"
                  label="Fonction"
                  outlined
              ></v-select>
            </v-col>
            <v-col cols="12"
                   md="6">
              <v-select
                  v-model="staff.service_id"
                  dense
                  name="services_list"
                  :item-text="item=>item.serv_name"
                  :item-value="'id'"
                  :items="services_list"
                  label="Services"
                  outlined
              ></v-select>
            </v-col>
            <v-col cols="12"
                   md="6">
              <v-text-field
                  v-model="staff.staff_salary_type"
                  dense
                  hide-details
                  label="Categorie salariale"
                  outlined
                  placeholder="Categorie"
              ></v-text-field>
            </v-col>
            <v-col cols="12"
                   md="6">
              <v-text-field
                  v-model="staff.staff_salary_amount"
                  dense
                  hide-details
                  type="number"
                  label="Salaire"
                  outlined
                  placeholder="Montant du salaire"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-btn
                  color="primary"
                  @click="newStaff"
              >
                Enregistrer
              </v-btn>
              <v-btn
                  class="mx-2"
                  outlined
                  type="reset"
              >
                Annuler
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
      <v-tab-item :value="'tab-'+1">
        <v-row>
          <v-col
              cols="12"
              md="12"
          >
            <span
                class="text-lg-h5  mb-4"
                style="justify-content: center; display: flex;"
            >
              Nouveau type de compte
            </span>
          </v-col>
        </v-row>
        <v-form
            class="multi-col-validation"
            style="margin-top:1rem; margin-left: 1rem; margin-bottom: 1.5rem;"
        >
          <v-row>
            <v-col
                cols="12"
                md="6"
            >
              <v-text-field
                  v-model="account_type.type_usr_name"
                  dense
                  hide-details
                  label="Nom"
                  outlined
                  placeholder="Designation"
              ></v-text-field>
            </v-col>

            <v-col
                cols="12"
                md="6"
            >
            </v-col>

            <v-col
                cols="12"
                md="6"
            >
            </v-col>
            <v-col cols="12">
              <v-btn
                  color="primary"
                  @click="newAccountType"
              >
                Creer
              </v-btn>
              <v-btn
                  class="mx-2"
                  outlined
                  type="reset"
              >
                Annuler
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
      <v-tab-item :value="'tab-'+2">
        <v-row>
          <v-col
              cols="12"
              md="12"
          >
            <span
                class="text-lg-h5  mb-4"
                style="justify-content: center; display: flex;"
            >
              Cr&eacute;er le compte utilisateur d'un employ&eacute;
            </span>
          </v-col>
        </v-row>
        <v-form
            class="multi-col-validation"
            style="margin-top:1rem; margin-left: 1rem; margin-bottom: 1.5rem;"
        >
          <v-row>
            <v-col
                cols="12"
                md="6"
            >
              <v-text-field
                  v-model="account.email"
                  dense
                  hide-details
                  label="Email"
                  outlined
                  placeholder="Address Mail"
              ></v-text-field>
            </v-col>

            <v-col
                cols="12"
                md="6"
            >
              <v-text-field
                  v-model="account.password"
                  dense
                  hide-details
                  label="Mot de passe"
                  outlined
                  placeholder="mot de passe"
              ></v-text-field>
            </v-col>

            <v-col
                cols="12"
                md="6"
            >
              <v-select
                  v-model="account.staff_id"
                  dense
                  name="staff_list"
                  :item-text="
                  'staff_name'"
                  :item-value="'id'"
                  :items="staff_list"
                  label="Employee Concerner"
                  outlined
              ></v-select>
            </v-col>
            <v-col
                cols="12"
                md="6"
            >
              <v-select
                  v-model="account.user_type_id"
                  dense
                  name="acc_type_list"
                  :item-text="'type_usr_name'"
                  :item-value="'id'"
                  :items="acc_type_list"
                  label="Type de compte"
                  outlined
              ></v-select>
            </v-col>
            <v-col
                cols="12"
                md="6"
            >
              <v-select
                  v-model="account.authorized_route"
                  dense
                  name="routes_list"
                  :item-text="
                  'route_name'"
                  :item-value="'route_url'"
                  multiple
                  :items="routes_list"
                  label="Acces"
                  outlined
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-btn
                  color="primary"
                  @click="newAccount"
              >
                Cr&eacute;er
              </v-btn>
              <v-btn
                  class="mx-2"
                  outlined
                  type="reset"
              >
                Annuler
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>

// import { ref } from '@vue/composition-api'
import {
  mdiBadgeAccount, mdiBriefcase,
} from '@mdi/js'

export default {
  name: 'UserStaff',
  data() {
    return {
      subTabs: [
        { title: 'Employé', icon: mdiBadgeAccount },
        { title: 'Type Compte', icon: mdiBriefcase },
        { title: 'Compte', icon: mdiBriefcase },
      ],
      tab_content: null,
      service: {
        serv_name: '',
      },
      function_user: {
        office_name: '',
      },
      staff: {
        staff_civility: '',
        staff_name: '',
        staff_pseudo: '',
        staff_contact: '',
        staff_address: '',
        staff_picture: '',
        staff_salary_type: '',
        staff_salary_amount: '',
        staff_sexe: '',
        office_id: '',
        service_id: '',
      },
      services_list: [],
      acc_type_list: [],
      staff_list: [],
      functions_list: [],
      routes_list: [],
      usersList: [],
      account: {
        email: '',
        password: '',
        staff_id: 0,
        user_type_id: 0,
        authorized_route: [],
      },
      account_type: {
        type_usr_name: '',
      },
    }
  },
  mounted() {
    this.loadService()
    this.loadOffice()
    this.loadStaff()
    this.loadRoute()
    this.loadAccountType()
  },
  methods: {
    loadOffice() {
      this.$axios.get(this.$endpoint.LoadOffice).then(rp => {
        this.functions_list = rp.data
      })
    },
    loadService() {
      this.$axios.get(this.$endpoint.LoadService).then(rp => {
        this.services_list = rp.data
      })
    },
    loadStaff() {
      this.$axios.get(this.$endpoint.LoadStaff).then(rp => {
        this.staff_list = rp.data
      })
    },
    loadAccountType() {
      this.$axios.get(this.$endpoint.LoadAccountType).then(rp => {
        this.acc_type_list = rp.data
      })
    },
    loadRoute() {
      this.$axios.get(this.$endpoint.LoadRoute).then(rp => {
        this.routes_list = rp.data
      })
    },
    newStaff() {
      this.$axios.post(this.$endpoint.CreateStaff, this.staff)
    },
    newAccount() {
      this.$axios.post(this.$endpoint.CreateAccount, this.account)
    },
    newAccountType() {
      this.$axios.post(this.$endpoint.CreateAccountType, this.account_type)
    },
  },
  setup() {
    return {}
  },
}
</script>
