<template>
  <div>
    <v-tabs
      v-model="tab_content"
      show-arrows
      style="margin-bottom:1rem;"
    >
      <v-tab
        href="#tab-0"
      >
        <v-icon
          class="me-3"
          size="20"
        >
          {{ subTabs[0].icon }}
        </v-icon>
        <span>{{ subTabs[0].title }}</span>
      </v-tab>
      <v-tab
        href="#tab-1"
      >
        <v-icon
          class="me-3"
          size="20"
        >
          {{ subTabs[1].icon }}
        </v-icon>
        <span>{{ subTabs[1].title }}</span>
      </v-tab>
      <v-tab
        href="#tab-2"
      >
        <v-icon
          class="me-3"
          size="20"
        >
          {{ subTabs[2].icon }}
        </v-icon>
        <span>{{ subTabs[2].title }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab_content">
      <v-tab-item :value="'tab-'+0">
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <span
              class="text-lg-h5  mb-4"
              style="justify-content: center; display: flex;"
            >
              Nouveau type de caisse
            </span>
          </v-col>
        </v-row>
        <v-form
          class="multi-col-validation"
          style="margin-top:1rem; margin-left: 1rem; margin-bottom: 1.5rem;"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="type_fund.fund_typ_name"
                dense
                hide-details
                label="Nom"
                outlined
                placeholder="Designation"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
            </v-col>
            <v-col cols="12">
              <v-btn
                color="primary"
                @click="newFundType"
              >
                Creer
              </v-btn>
              <v-btn
                class="mx-2"
                outlined
                type="reset"
              >
                Annuler
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
      <v-tab-item :value="'tab-'+1">
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <span
              class="text-lg-h5  mb-4"
              style="justify-content: center; display: flex;"
            >
              Nouvelle Caisse
            </span>
          </v-col>
        </v-row>
        <v-form
          class="multi-col-validation"
          style="margin-top:1rem; margin-left: 1rem; margin-bottom: 1.5rem;"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                  v-model="fund_nc"
                  dense
                  :items="fund_list"
                  :item-text="item=>item.name"
                  :item-value="item=>item"
                  label="Choisir une caisse"
                  outlined
                  @change="setFundData"
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="fund.fund_amount"
                dense
                type="number"
                hide-details
                label="Montant"
                outlined
                placeholder="montant de la caisse"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="fund.fund_type_id"
                dense
                name="type_fund_list"
                :item-text="'fund_typ_name'"
                :item-value="'id'"
                :items="type_fund_list"
                label="Type de caisse"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="primary"
                @click="newFund"
              >
                Creer
              </v-btn>
              <v-btn
                class="mx-2"
                outlined
                type="reset"
              >
                Annuler
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
      <v-tab-item :value="'tab-'+2">
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <span
              class="text-lg-h5  mb-4"
              style="justify-content: center; display: flex;"
            >
              Nouveau type de Paiement
            </span>
          </v-col>
        </v-row>
        <v-form
          class="multi-col-validation"
          style="margin-top:1rem; margin-left: 1rem; margin-bottom: 1.5rem;"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="type_payement.pay_typ_name"
                dense
                hide-details
                label="Nom"
                outlined
                placeholder="Designation"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
            </v-col>
            <v-col cols="12">
              <v-btn
                color="primary"
                @click="newPaymentType"
              >
                Creer
              </v-btn>
              <v-btn
                class="mx-2"
                outlined
                type="reset"
              >
                Annuler
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mdiBadgeAccount, mdiBriefcase } from '@mdi/js'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Treasury',
  data() {
    return {
      subTabs: [
        { title: 'Type Caisse', icon: mdiBadgeAccount },
        { title: 'Caisse', icon: mdiBriefcase },
        { title: 'Type Payement', icon: mdiBriefcase },
      ],
      tab_content: null,
      type_payement: {
        pay_typ_name: '',
      },
      type_fund: {
        fund_typ_name: '',
      },
      fund_nc: {},
      fund_list: [
        { name: 'Caisse Appatement Meublé', code: 1000 },
        { name: 'Caisse Appatement Menseulle', code: 1100 },
        { name: 'Caisse Bar', code: 1200 },
        { name: 'Caisse Restaurant', code: 1300 },
      ],
      fund: {
        fund_name: '',
        fund_amount: 0.00,
        fund_code: 0,
        fund_type_id: 0,
      },
      type_fund_list: [
        { id: 1, name: 'Caisse Studios Meubler' },
        { id: 2, name: 'Caisse Appartement Mensuelle' },
      ],
    }
  },
  mounted() {
    this.loadFundType()
  },
  methods: {
    newFund() {
      this.$axios.post(this.$endpoint.CreatFund, this.fund)
    },
    setFundData() {
      this.fund.fund_name = this.fund_nc.name
      this.fund.fund_code = this.fund_nc.code
    },
    newFundType() {
      this.$axios.post(this.$endpoint.CreateFundType, this.type_fund)
    },
    newPaymentType() {
      this.$axios.post(this.$endpoint.CreatePaymentType, this.type_payement)
    },
    loadFundType() {
      this.$axios.get(this.$endpoint.LoadFundType).then(rp => {
        this.type_fund_list = rp.data
      })
    },
  },
}
</script>

<style scoped>

</style>
