<template>
    <v-card id="account-setting-card">
      <!-- tabs -->
      <v-tabs
          v-model="tab"
          show-arrows
          style="margin-bottom:2rem;"
      >
        <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
        >
          <v-icon
              size="20"
              class="me-3"
          >
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <!-- tabs item -->
      <v-tabs-items
          v-model="tab"
      >
        <v-tab-item>
          <ModifCited></ModifCited>
        </v-tab-item>
        <v-tab-item>
          <div>
            service
          </div>
        </v-tab-item>

        <v-tab-item>
          <div>
            stock
          </div>
        </v-tab-item>

        <v-tab-item>
          <div>
            type de compte
          </div>
        </v-tab-item>
        <v-tab-item>
          <div>
            tresorerie
          </div>
        </v-tab-item>
<!--        <v-tab-item>-->
<!--          <Modification></Modification>-->
<!--        </v-tab-item>-->
      </v-tabs-items>
    </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import  ModifCited from '@/views/configuration/modification/cited/ModifCited'
import {
  mdiAccountOutline, mdiInformationOutline,
  mdiLockOpenOutline, mdiHomeOutline, mdiAccount, mdiBank,
  mdiAccountEdit,
} from '@mdi/js'
export default {
  name: 'Modification',
  components: {
    ModifCited,
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'Cité', icon: mdiHomeOutline },
      { title: 'Services & Fonction', icon: mdiLockOpenOutline },
      { title: 'Stock & Fourniture', icon: mdiInformationOutline },
      { title: 'Personnel', icon: mdiAccount },
      { title: 'Trésorerie', icon: mdiBank },
      { title: 'Modification', icon: mdiAccountEdit },
    ]

    return {
      tab,
      tabs,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBank,
      },
    }
  },
  data() {
    return {
      modif: false,
    }
  },
}
</script>

<style scoped>

</style>
