<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
      style="margin-bottom:2rem;"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items
      v-model="tab"
    >
      <v-tab-item>
        <FormApartement></FormApartement>
      </v-tab-item>
      <v-tab-item>
        <Services></Services>
      </v-tab-item>

      <v-tab-item>
        <StocksSupplies></StocksSupplies>
      </v-tab-item>

      <v-tab-item>
        <UserStaff></UserStaff>
      </v-tab-item>
      <v-tab-item>
        <Treasury></Treasury>
      </v-tab-item>
      <v-tab-item>
        <Modification></Modification>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'

import {
  mdiAccountOutline, mdiInformationOutline,
  mdiLockOpenOutline, mdiHomeOutline, mdiAccount, mdiBank,
    mdiAccountEdit,
} from '@mdi/js'

// eslint-disable-next-line import/extensions
import FormApartement from '@/views/configuration/FormApartement'
// eslint-disable-next-line import/extensions
import Services from '@/views/configuration/Services'
// eslint-disable-next-line import/extensions
import StocksSupplies from '@/views/configuration/StocksSupplies'
// eslint-disable-next-line import/extensions
import UserStaff from '@/views/configuration/UserStaff'
// eslint-disable-next-line import/extensions
import Treasury from '@/views/configuration/Treasury'

import Modification from '@/views/configuration/modification/Modification'

export default {
  name: 'Configuration',
  components: {
    FormApartement,
    Services,
    StocksSupplies,
    UserStaff,
    Treasury,
    Modification,
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'Cité', icon: mdiHomeOutline },
      { title: 'Services & Fonction', icon: mdiLockOpenOutline },
      { title: 'Stock & Fourniture', icon: mdiInformationOutline },
      { title: 'Personnel', icon: mdiAccount },
      { title: 'Trésorerie', icon: mdiBank },
      { title: 'Modification', icon: mdiAccountEdit },
    ]

    return {
      tab,
      tabs,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBank,
      },
    }
  },
}
</script>

<style scoped>

</style>
