<template>
<div>
  <v-data-table
      :headers="headers"
      :items="list_room"
      item-key="id"
      class="table-rounded"
      :search="search"
  >
    <template #top>
      <v-toolbar
          flat
      >
        <v-toolbar-title>Liste des Chambres</v-toolbar-title>
      </v-toolbar>
      <v-spacer></v-spacer>
      <v-row style="margin-left: 1.5rem; margin-right: 1.5rem;">
        <!--          class="d-flex"-->
        <v-col
            cols="12"
            sm="4"
        >
        </v-col>
        <!--          class="d-flex"-->
        <v-col
            cols="12"
            sm="4"
        >
        </v-col>
        <!--          class="d-flex"-->
        <v-col
            cols="12"
            sm="4"
        >
          <v-text-field
              v-model="search"
              dense
              append-icon="mdi-magnify"
              label="Rechercher un client"
              outlined
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.option="{item}">
      <v-btn color="primary" class="ma-2" @click="ModifyRoom(item)">
        MODIFIER
      </v-btn>
    </template>

  </v-data-table>
  <!--    dialog to modify room type begin-->
  <template>
    <v-row justify="center">
      <v-dialog
          v-model="modifyRoomType"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-btn
                color="primary"
                @click="modifyRoomType = false"
            >
              <mdi-close-circle />Annuler
            </v-btn>&emsp;
            <v-toolbar-title color="white">
              Modifier ce type de chambre
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <ModifRoomTypeForm @succes-room-type="closeForm" :room_type="roomTypeTemp"></ModifRoomTypeForm>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  <!--    dialog to modify room type end-->
</div>
</template>

<script>
export default {
  name: 'RoomList',
  data() {
    return {
      list_room: [],
      search: '',
      headers: [
        { text: 'NOM ', value: 'room_name' },
        { text: 'prix ', value: 'room_total_price' },
        { text: 'TYPE ', value: 'room_type.room_type_name' },
        { text: 'APPARTEMENT ', value: 'apartment.apart_bloc' },
        { text: 'OPTION', value: 'option' },
      ],
    }
  },
  mounted() {
    this.loadRoom()
  },
  methods: {
    loadRoom() {
      this.$axios.get(this.$endpoint.LoadRoom).then(rp => {
        this.list_room = rp.data
      })
    },
    ModifyRoom(room) {
      console.log(room)
    },
  },
}
</script>

<style scoped>

</style>
