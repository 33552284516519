<template>
  <div>
    <v-tabs
      v-model="tab_content"
      show-arrows
      style="margin-bottom:1rem;"
    >
      <v-tab
        href="#tab-0"
      >
        <v-icon
          class="me-3"
          size="20"
        >
          {{ subTabs[0].icon }}
        </v-icon>
        <span>{{ subTabs[0].title }}</span>
      </v-tab>
      <v-tab
        href="#tab-1"
      >
        <v-icon
          class="me-3"
          size="20"
        >
          {{ subTabs[1].icon }}
        </v-icon>
        <span>{{ subTabs[1].title }}</span>
      </v-tab>
      <v-tab
        href="#tab-2"
      >
        <v-icon
          class="me-3"
          size="20"
        >
          {{ subTabs[2].icon }}
        </v-icon>
        <span>{{ subTabs[2].title }}</span>
      </v-tab>
      <v-tab
        href="#tab-3"
      >
        <v-icon
          class="me-3"
          size="20"
        >
          {{ subTabs[3].icon }}
        </v-icon>
        <span>{{ subTabs[3].title }}</span>
      </v-tab>
      <v-tab
        href="#tab-4"
      >
        <v-icon
          class="me-3"
          size="20"
        >
          {{ subTabs[4].icon }}
        </v-icon>
        <span>{{ subTabs[4].title }}</span>
      </v-tab>
      <v-tab
          href="#tab-5"
      >
        <v-icon
            class="me-3"
            size="20"
        >
          {{ subTabs[5].icon }}
        </v-icon>
        <span>{{ subTabs[5].title }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab_content">
      <v-tab-item :value="'tab-'+0">
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <span
              class="text-lg-h5  mb-4"
              style="justify-content: center; display: flex;"
            >
              Nouveau type de chambre
            </span>
          </v-col>
        </v-row>
        <v-form
          class="multi-col-validation"
          style="margin-top:1rem; margin-left: 1rem; margin-bottom: 1.5rem;"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="room_type.room_type_name"
                dense
                hide-details
                label="Designation"
                outlined
                placeholder="Designation"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
            </v-col>

            <v-col cols="12">
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                @click="newRoomType"
              >
                Creer
              </v-btn>
              <v-btn
                class="mx-2"
                outlined
                type="reset"
              >
                Annuler
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
      <v-tab-item :value="'tab-'+1">
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <span
              class="text-lg-h5  mb-4"
              style="justify-content: center; display: flex;"
            >
              Nouvelle chambre
            </span>
          </v-col>
        </v-row>
        <v-form
          class="multi-col-validation"
          style="margin-top:1rem; margin-left: 1rem; margin-bottom: 1.5rem;"
          @submit.prevent="newRoom($event.target)"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="room.room_name"
                dense
                hide-details
                name="room_name"
                label="Numéro de la chambre"
                outlined
                required
                placeholder="Numéro"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="room.room_create_date"
                dense
                required
                type="date"
                name="room_create_date"
                hide-details
                label="Date de création"
                outlined
                placeholder="Création"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="room.room_unit_price"
                dense
                required
                name="room_unit_price"
                hide-details
                label="Prix unitaire"
                outlined
                placeholder="prix"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="room.room_total_price"
                dense
                name="room_total_price"
                required
                hide-details
                label="Prix de vente"
                outlined
                placeholder="prix de vente"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="room.room_type_id"
                dense
                required
                name="room_type_id"
                :item-text="'room_type_name'"
                :item-value="'id'"
                :items="roomtypes_list"
                label="Type de chambre"
                outlined
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <!--                :value="apartments_list.id"-->
              <v-select
                v-model="room.apartment_id"
                dense
                required
                :items="apartments_list"
                name="apartment_id"
                :item-text="'apart_bloc'"
                :item-value="'id'"
                label="Block d'appartement concerné"
                outlined
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-file-input
                  v-model="room.room_photos"
                  dense
                  required
                  name="room_photos[]"
                  type="file"
                  multiple
                  hide-details
                  label="Images de la chambre"
                  outlined
                  placeholder="Selectionner les photos de la chambres"
              ></v-file-input>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                  v-model="room.room_state"
                  :item-text="'label'"
                  :item-value="'code'"
                  :items="roomStatus"
                  dense
                  label="Etat"
                  name="room_state"
                  outlined
                  required
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-btn color="primary" type="submit">
                Creer
              </v-btn>
              <v-btn
                class="mx-2"
                outlined
                type="reset"
              >
                Annuler
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
      <v-tab-item :value="'tab-'+2">
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <span
              class="text-lg-h5  mb-4"
              style="justify-content: center; display: flex;"
            >
              Nouveau block
            </span>
          </v-col>
        </v-row>
        <v-form
          class="multi-col-validation"
          style="margin-top:1rem;margin-left: 1rem; margin-bottom: 1.5rem;"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="apartment.apart_bloc"
                dense
                hide-details
                label="Nom"
                outlined
                placeholder="Designation"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="apartment.apart_address"
                dense
                hide-details
                label="Addresse"
                outlined
                placeholder="Lieu"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
            </v-col>

            <v-col cols="12">
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                @click="newApartment"
              >
                Creer
              </v-btn>
              <v-btn
                class="mx-2"
                outlined
                type="reset"
              >
                Annuler
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
      <v-tab-item :value="'tab-'+3">
        <v-card-text class="d-flex">
          <v-avatar
            rounded
            size="120"
            class="me-6"
          >
            <v-img src="@/assets/images/logo/logo_1.jpeg"></v-img>
          </v-avatar>

          <!-- upload photo -->
          <div>
            <v-btn
              color="primary"
              class="me-3 mt-5"
            >
              <v-icon class="d-sm-none">
               mdiCloudUploadOutline
              </v-icon>
              <span class="d-none d-sm-block">Changer</span>
            </v-btn>

            <input
              ref="refInputEl"
              type="file"
              accept=".jpeg,.png,.jpg,GIF"
              :hidden="true"
            />
            <p class="text-sm mt-5">
              format autorisés: JPG, GIF or PNG.
            </p>
          </div>
        </v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <span
              class="text-lg-h5  mb-4"
              style="justify-content: center; display: flex;"
            >
              Informations de la cite
            </span>
          </v-col>
        </v-row>
        <v-form
          class="multi-col-validation"
          style="margin-top:1rem;margin-left: 1rem; margin-bottom: 1.5rem;"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="cited.cited_name"
                dense
                hide-details
                label="Nom"
                outlined
                placeholder="Non de la citée"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="cited.cited_create_date"
                dense
                type="date"
                hide-details
                label="Date de création"
                outlined
                placeholder=""
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="cited.cited_address"
                dense
                hide-details
                label="Address"
                outlined
                placeholder="Code postale"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="cited.cited_cellular"
                dense
                hide-details
                label="Contact"
                outlined
                placeholder="Numéro de telephone"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="cited.cited_city"
                dense
                hide-details
                label="Ville"
                outlined
                placeholder="Ville"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="primary"
                @click="addTypeRoom"
              >
                Modifier
              </v-btn>
              <v-btn
                class="mx-2"
                outlined
                type="reset"
              >
                Annuler
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
      <v-tab-item :value="'tab-'+4">
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <span
              class="text-lg-h5  mb-4"
              style="justify-content: center; display: flex;"
            >
              Nouveau &eacute;quipement
            </span>
          </v-col>
        </v-row>
        <v-form
          class="multi-col-validation"
          style="margin-top:1rem;margin-left: 1rem; margin-bottom: 1.5rem;"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="equipment.equipment_name"
                dense
                hide-details
                label="Nom"
                outlined
                placeholder="Designation"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="equipment.equipment_amount"
                dense
                hide-details
                type="numeric"
                label="Coût"
                outlined
                placeholder="Prix"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="equipment.equipment_quality"
                dense
                hide-details
                label="Qualité"
                outlined
                placeholder="Marque"
              ></v-text-field>
            </v-col>

            <v-col cols="12"
            md="6">
              <v-text-field
                v-model="equipment.equipment_type"
                dense
                hide-details
                label="Type"
                outlined
                placeholder="exemple: électronique"
              ></v-text-field>
            </v-col>
            <v-col cols="12"
                   md="6">
              <v-text-field
                v-model="equipment.equipment_state"
                dense
                hide-details
                label="Etat"
                outlined
                placeholder="exemple: fonctionnel, hors service"
              ></v-text-field>
            </v-col>
            <v-col cols="12"
                   md="6">
              <v-select
                v-model="equipment.room_id"
                dense
                name="room_list"
                :item-text="'room_name'"
                :item-value="'id'"
                :items="room_list"
                label="Chambre"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="primary"
                @click="newEquipment"
              >
                Creer
              </v-btn>
              <v-btn
                class="mx-2"
                outlined
                type="reset"
              >
                Annuler
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
      <v-tab-item :value="'tab-'+5">
        <v-row>
          <v-col
              cols="12"
              md="12"
          >
            <span
                class="text-lg-h5  mb-4"
                style="justify-content: center; display: flex;"
            >
              Nouveau type de sejour
            </span>
          </v-col>
        </v-row>
        <v-form
            class="multi-col-validation"
            style="margin-top:1rem; margin-left: 1rem; margin-bottom: 1.5rem;"
        >
          <v-row>
            <v-col
                cols="12"
                md="6"
            >
              <v-text-field
                  v-model="stay_type.stay_typ_name"
                  dense
                  hide-details
                  label="Nom"
                  outlined
                  placeholder="Nom du type de sejour"
              ></v-text-field>
            </v-col>

            <v-col
                cols="12"
                md="6"
            >
            </v-col>

            <v-col
                cols="12"
                md="6"
            >
            </v-col>

            <v-col cols="12">
            </v-col>

            <v-col cols="12">
              <v-btn
                  color="primary"
                  @click="newStayType"
              >
                Creer
              </v-btn>
              <v-btn
                  class="mx-2"
                  outlined
                  type="reset"
              >
                Annuler
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>

// import { ref } from '@vue/composition-api'
import {
  mdiOfficeBuilding, mdiOfficeBuildingOutline, mdiRoomService, mdiTooltipCheckOutline,
} from '@mdi/js'

export default {
  data() {
    return {
      subTabs: [
        { title: 'Type de chambre', icon: mdiRoomService },
        { title: 'Chambre', icon: mdiRoomService },
        { title: 'Appartement', icon: mdiOfficeBuilding },
        { title: 'Infos cite', icon: mdiOfficeBuildingOutline },
        { title: 'Equipement', icon: mdiTooltipCheckOutline },
        { title: 'Type de sejour', icon: mdiRoomService },
      ],
      tab_content: null,
      roomStatus: [
        {
          label: 'En service',
          code: 30,
        },
        {
          label: 'Hors service',
          code: 0,
        },
        {
          label: 'Occupé',
          code: 405,
        },
      ],
      room_type: {
        room_type_name: '',
      },
      stay_type: {
        stay_typ_name: '',
      },
      apartment: {
        apart_bloc: '',
        apart_address: '',
        cited_id: 1,
      },
      room: {
        room_name: '',
        room_create_date: '',
        room_status: 400,
        room_unit_price: 0,
        room_total_price: 0,
        room_photos: [],
        room_state: '',
        apartment_id: 0,
        room_type_id: 0,
      },
      roomtypes_list: [
        { id: 1, name: 'studios 2 piece' },
        { id: 2, name: 'studios 3 piece' },
        { id: 3, name: 'salle de reunion' },
      ],
      room_list: [
        { id: 1, name: 'chambre e24' },
        { id: 2, name: 'chambre b21' },
        { id: 3, name: 'chambre a15' },
      ],
      apartments_list: [
        { id: 1, name: 'Batiment A' },
        { id: 2, name: 'Batiment E' },
      ],
      cited: {
        cited_name: '',
        cited_create_date: '',
        cited_logo: '',
        cited_address: '',
        cited_cellular: '',
        cited_city: '',
      },
      equipment: {
        equipment_name: '',
        equipment_type: '',
        equipment_state: '',
        equipment_quality: '',
        equipment_amount: '',
        room_id: 0,
      },
    }
  },
  mounted() {
    this.loadCited()
    this.loadRoom()
    this.loadApartments()
    this.loadRoomType()
  },
  methods: {
    addTypeRoom() {
      console.log('room add')
    },
    loadCited(){
      this.$axios.post(this.$endpoint.LoadCited).then(rp=>{
        this.cited = rp.data
      })
    },
    loadRoom() {
      this.$axios.get(this.$endpoint.LoadRoom).then(rp=>{
        this.room_list = rp.data.filter(room => room.room_status === 400)
      })
    },
    loadApartments() {
      this.$axios.get(this.$endpoint.LoadApartment).then(rp=>{
        this.apartments_list = rp.data
      })
    },
    loadRoomType() {
      this.$axios.get(this.$endpoint.LoadRoomType).then(rp=>{
        this.roomtypes_list = rp.data
        console.log(rp)
      })
    },
    modifiedCited(){
      this.$axios.post(this.$endpoint.ModifiedCited,this.cited).then(rp=>{
        this.cited = rp.data
      })
    },
    newEquipment(){
      this.$axios.post(this.$endpoint.CreateEquipment, this.equipment)
    },
    newRoomType() {
      console.log(this.room_type.room_type_name)
      this.$axios.post(this.$endpoint.CreateRoomType, { room_type_name: this.room_type.room_type_name })
    },
    newApartment() {
      this.$axios.post(this.$endpoint.CreateApartment, this.apartment).then(rp => {
        console.log(rp)
      })
    },
    newRoom(e) {
      this.$axios.post(this.$endpoint.CreateRoom, new FormData(e), {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    },
    newStayType() {
      this.$axios.post(this.$endpoint.CreateStayType, this.stay_type)
    },
  },
  setup() {
    return {

    }
  },
}
</script>
