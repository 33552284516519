<template>
  <div>
    <v-tabs
      v-model="tab_content"
      show-arrows
      style="margin-bottom:1rem;"
    >
      <v-tab
        href="#tab-0"
      >
        <v-icon
          class="me-3"
          size="20"
        >
          {{ subTabs[0].icon }}
        </v-icon>
        <span>{{ subTabs[0].title }}</span>
      </v-tab>
      <v-tab
        href="#tab-1"
      >
        <v-icon
          class="me-3"
          size="20"
        >
          {{ subTabs[1].icon }}
        </v-icon>
        <span>{{ subTabs[1].title }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab_content">
      <v-tab-item :value="'tab-'+0">
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <span
              class="text-lg-h5  mb-4"
              style="justify-content: center; display: flex;"
            >
              Nouveau Stock
            </span>
          </v-col>
        </v-row>
        <v-form
          class="multi-col-validation"
          style="margin-top:1rem; margin-left: 1rem; margin-bottom: 1.5rem;"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="stock.stock_designation"
                dense
                hide-details
                label="Désignation"
                outlined
                placeholder="Désignation"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="stock.stock_create_date"
                dense
                type="date"
                hide-details
                label="Date de création"
                outlined
                placeholder="Date de création"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="stock.stock_amount"
                dense
                type="number"
                hide-details
                label="Quantité"
                outlined
                placeholder="Nombre de fournirture"
              ></v-text-field>
            </v-col>

            <v-col cols="12"
                   md="6">
              <v-select
                v-model="stock.supply_id"
                dense
                name="supplies_list"
                :item-text="'sup_name'"
                :item-value="'id'"
                :items="supplies_list"
                label="Fourniture"
                outlined
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                @click="newStock"
              >
                Creer
              </v-btn>
              <v-btn
                class="mx-2"
                outlined
                type="reset"
              >
                Annuler
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
      <v-tab-item :value="'tab-'+1">
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <span
              class="text-lg-h5  mb-4"
              style="justify-content: center; display: flex;"
            >
              Nouvelle Fourniture
            </span>
          </v-col>
        </v-row>
        <v-form
          class="multi-col-validation"
          style="margin-top:1rem; margin-left: 1rem; margin-bottom: 1.5rem;"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="supply.sup_name"
                dense
                hide-details
                label="Désignation"
                outlined
                placeholder="Désignation"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="supply.sup_mark"
                dense
                hide-details
                label="Marque"
                outlined
                placeholder="Marque"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="supply.sup_price"
                dense
                type="number"
                hide-details
                label="Coût"
                outlined
                placeholder="Coût"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="supply.sup_type"
                dense
                hide-details
                label="Type"
                outlined
                placeholder="Type"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="primary"
                @click="newSupply"
              >
                Creer
              </v-btn>
              <v-btn
                class="mx-2"
                outlined
                type="reset"
              >
                Annuler
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>

// import { ref } from '@vue/composition-api'
import {
  mdiDatabase, mdiTruckDeliveryOutline,
} from '@mdi/js'

export default {
  name: 'StocksSupplies',
  data() {
    return {
      subTabs: [
        { title: 'Stock', icon: mdiDatabase },
        { title: 'Fourniture', icon: mdiTruckDeliveryOutline },
      ],
      tab_content: null,
      stock: {
        stock_designation: '',
        stock_create_date: '',
        stock_amount: '',
        stock_status: '',
        supply_id: '',
      },
      supplies_list: [
        { id: 1, name: 'lit' },
        { id: 2, name: 'cousin' },
        { id: 3, name: 'micro-onde' },
      ],
      supply: {
        sup_type: '',
        sup_name: '',
        sup_mark: '',
        sup_price: 0,
      },
    }
  },
  mounted() {
    this.loadSupply()
  },
  methods: {
    newStock() {
      this.$axios.post(this.$endpoint.CreateStock, this.stock)
    },
    newSupply() {
      this.$axios.post(this.$endpoint.CreateSupply, this.supply)
    },
    loadSupply() {
      this.$axios.get(this.$endpoint.LoadSupplies).then(rp => {
        this.supplies_list = rp.data
      })
    },
  },
  setup() {
    return {

    }
  },
}
</script>
