<template>
  <div>
    <v-tabs
      v-model="tab_content"
      show-arrows
      style="margin-bottom:1rem;"
    >
      <v-tab
        href="#tab-0"
      >
        <v-icon
          class="me-3"
          size="20"
        >
          {{ subTabs[0].icon }}
        </v-icon>
        <span>{{ subTabs[0].title }}</span>
      </v-tab>
      <v-tab
        href="#tab-1"
      >
        <v-icon
          class="me-3"
          size="20"
        >
          {{ subTabs[1].icon }}
        </v-icon>
        <span>{{ subTabs[1].title }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab_content">
      <v-tab-item :value="'tab-'+0">
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <span
              class="text-lg-h5  mb-4"
              style="justify-content: center; display: flex;"
            >
              Nouveau Service
            </span>
          </v-col>
        </v-row>
        <v-form
          class="multi-col-validation"
          style="margin-top:1rem; margin-left: 1rem; margin-bottom: 1.5rem;"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="service.serv_name"
                dense
                hide-details
                label="Nom"
                outlined
                placeholder="Nom"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
            </v-col>

            <v-col cols="12">
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                @click="newService"
              >
                Creer
              </v-btn>
              <v-btn
                class="mx-2"
                outlined
                type="reset"
              >
                Annuler
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
      <v-tab-item :value="'tab-'+1">
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <span
              class="text-lg-h5  mb-4"
              style="justify-content: center; display: flex;"
            >
              Nouvelle Fonction
            </span>
          </v-col>
        </v-row>
        <v-form
          class="multi-col-validation"
          style="margin-top:1rem; margin-left: 1rem; margin-bottom: 1.5rem;"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="office.office_name"
                dense
                hide-details
                label="Nom"
                outlined
                placeholder="Nom"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
            </v-col>
            <v-col cols="12">
              <v-btn
                color="primary"
                @click="newFunction"
              >
                Creer
              </v-btn>
              <v-btn
                class="mx-2"
                outlined
                type="reset"
              >
                Annuler
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>

// import { ref } from '@vue/composition-api'
import {
  mdiBadgeAccount, mdiBriefcase,
} from '@mdi/js'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Services',
  data() {
    return {
      subTabs: [
        { title: 'Service', icon: mdiBadgeAccount },
        { title: 'Fonctions', icon: mdiBriefcase },
      ],
      tab_content: null,
      service: {
        serv_name: '',
      },
      office: {
        office_name: '',
      },
    }
  },
  methods: {
    addTypeRoom() {
      console.log('room add')
    },
    newFunction() {
      this.$axios.post(this.$endpoint.CreateFunction, this.office)
    },
    newService() {
      this.$axios.post(this.$endpoint.CreateService, this.service)
    },
  },
  setup() {
    return {

    }
  },
}
</script>
