<template>
<div>
  <v-row>
    <v-col
        cols="12"
        md="12"
    >
            <span
                class="text-lg-h5  mb-4"
                style="justify-content: center; display: flex;"
            >
              Modifier ce type de chambre
            </span>
    </v-col>
  </v-row>
  <v-form
      class="multi-col-validation"
      style="margin-top:1rem; margin-left: 1rem; margin-bottom: 1.5rem;"
  >
    <v-row>
      <v-col
          cols="12"
          md="6"
      >
        <v-text-field
            v-model="room_type.room_type_name"
            dense
            hide-details
            label="Designation"
            outlined
            placeholder="Designation"
        ></v-text-field>
      </v-col>

      <v-col
          cols="12"
          md="6"
      >
      </v-col>

      <v-col
          cols="12"
          md="6"
      >
      </v-col>

      <v-col cols="12">
      </v-col>

      <v-col cols="12">
        <v-btn
            color="primary"
            @click="updateRoomType"
        >
          Modifier
        </v-btn>
        <v-btn
            class="mx-2"
            outlined
            type="reset"
        >
          Annuler
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</div>
</template>

<script>
export default {
  name: 'ModifRoomTypeForm',
  // eslint-disable-next-line vue/require-prop-types,vue/prop-name-casing
  props: ['room_type'],
  methods: {
    updateRoomType() {
      this.$axios.put(this.$endpoint.UpdateRoomType, this.room_type).then(rp => {
        this.$emit('succes-room-type')
        console.log(rp)
      })
    },
  },
}
</script>

<style scoped>

</style>
