<template>
<div>
  <v-data-table
      :headers="headers"
      :items="roomtypes_list"
      item-key="id"
      class="table-rounded"
      :search="search"
  >
    <template #top>
      <v-toolbar
          flat
      >
        <v-toolbar-title>Liste des Types de Chambre</v-toolbar-title>
      </v-toolbar>
      <v-spacer></v-spacer>
      <v-row style="margin-left: 1.5rem; margin-right: 1.5rem;">
        <!--          class="d-flex"-->
        <v-col
            cols="12"
            sm="4"
        >
        </v-col>
        <!--          class="d-flex"-->
        <v-col
            cols="12"
            sm="4"
        >
        </v-col>
        <!--          class="d-flex"-->
        <v-col
            cols="12"
            sm="4"
        >
          <v-text-field
              v-model="search"
              dense
              append-icon="mdi-magnify"
              label="Rechercher un client"
              outlined
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.option="{item}">
     <v-btn color="primary" class="ma-2" @click="ModifyRoomType(item)">
       MODIFIER
     </v-btn>
      <v-btn  color="primary" @click="DeleteRoomType(item)">
        SUPPRIMER
      </v-btn>
    </template>

  </v-data-table>
  <!--    dialog to modify room type begin-->
  <template>
    <v-row justify="center">
      <v-dialog
          v-model="modifyRoomType"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-btn
                color="primary"
                @click="modifyRoomType = false"
            >
              <mdi-close-circle />Annuler
            </v-btn>&emsp;
            <v-toolbar-title color="white">
              Modifier ce type de chambre
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <ModifRoomTypeForm @succes-room-type="closeForm" :room_type="roomTypeTemp"></ModifRoomTypeForm>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  <!--    dialog to modify room type end-->
</div>
</template>

<script>
import ModifRoomTypeForm from '@/views/configuration/modification/cited/ModifRoomTypeForm'

export default {
  name: 'RoomTypeList',
  components: {ModifRoomTypeForm},
  data() {
    return {
      modifyRoomType: false,
      headers: [
        { text: 'NOM DU TYPE', value: 'room_type_name' },
        { text: 'OPTION', value: 'option' },
      ],
      roomTypeTemp: {},
      roomtypes_list: [],
      search: '',
    }
  },
  mounted() {
    this.loadRoomType()
  },
  methods: {
    loadRoomType() {
      this.$axios.get(this.$endpoint.LoadRoomType).then(rp => {
        this.roomtypes_list = rp.data
        console.log(rp)
      })
    },
    ModifyRoomType(rm) {
      // console.log(rm)
      this.roomTypeTemp = rm
      this.modifyRoomType = true
    },
    closeForm() {
      this.loadRoomType()
      this.modifyRoomType = false
    },
    DeleteRoomType(rm) {
      this.$axios.delete(this.$endpoint.DeleteRoomType(rm.id)).then(rp => {
        this.roomtypes_list = rp.data
      }).then(rp => {
        this.loadRoomType()
        console.log(rp)
      })
    },
  },
}
</script>

<style scoped>

</style>
